<template>
  <head-meta service="CGBank" />
  <div
    id="top"
    class="cb-layout"
  >
    <l-navs />
    <main class="main">
      <NuxtPage />
    </main>
    <l-contact />
    <l-footer />
    <l-scroll-top-btn />
  </div>

  <Dialog
    :id="dialog.id"
    v-for="dialog in dialogStore.dialogs"
    :key="dialog.id"
  />
</template>

<script setup lang="ts">
const dialogStore = useDialogStore()
</script>

<style lang="scss" scoped>
.cb-layout {
  width: 100%;
  min-height: 100dvh;
  font-weight: 500;
  font-style: normal;
  font-family: $japanese;
  display: flex;
  flex-direction: column;
  color: var(--secondary);
  background-color: var(--background);

  > .main {
    flex-grow: 1;
    border-top: 1px solid var(--secondary);
    border-bottom: 1px solid var(--secondary);
    min-height: 50vh;
  }
}
</style>
